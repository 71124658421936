import React, { useState } from "react";
import SelectDropdown from "./SelectDropdown";
import Dropdown from "./Dropdown";

/**
 * Get links to all important landing pages on Amazon
 * @param desc - Text describing link finder functionality
 * @param categories - First level category array
 * @param links - Link Array
 */
const LinkFinder = ({ desc, categories, links, title, categoryText, linkText }) => {
  const [activeCategory, setActiveCategory] = useState(null);

  if (!categories || categories.length < 1 || !links || links.length < 1)
    return null;

  return (
    <section className="link-finder">
      <h5 className="link-finder__title">{title}</h5>
      {desc && <p className="link-finder__desc">{desc}</p>}
      <SelectDropdown
        onClick={element => setActiveCategory(element)}
        id="category"
        defaultText={categoryText}
      >
        {categories &&
          categories.map(category => (
            <SelectDropdown.Item key={category.node.title}>
              {category.node.title}
            </SelectDropdown.Item>
          ))}
      </SelectDropdown>
      <Dropdown
        id="sub_category"
        title={linkText}
        variant="select"
        filter={activeCategory ? activeCategory : ""}
      >
        {links.map(link => (
          <Dropdown.Item
            key={link.node.url}
            to={link.node.url}
            isOutsideLink
            dataFilter={link.node.parent_title}
          >
            {link.node.title}
          </Dropdown.Item>
        ))}
      </Dropdown>
    </section>
  );
};

LinkFinder.defaultProps = {
  title: "Link Finder",
  categoryText: "Choose Category",
  linkText: "Choose Link",
}

export default LinkFinder;
