import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

const ClickContext = React.createContext();

// Custom Hook
function useEffectAfterMount(cb, dependencies) {
  const justMounted = React.useRef(true);
  React.useEffect(() => {
    if (!justMounted.current) {
      return cb();
    }
    justMounted.current = false;
  }, [dependencies, cb]);
}

/**
 * Kind of like Dropdown Component but with a callback when a Dropdown.Item was clicked. Also displays the current active item.
 *
 * Example: Used for Link Finder First Category "Select". We have to know which category was clicked to filter the second dropdown component.
 *
 * @see: {@link https://kentcdodds.com/blog/compound-components-with-react-hooks}
 *
 * @param onClick - onClick callback function. First argument is the text of the clicked Item
 * @param id - Id of the toggle button. Used in aria-labelledby
 * @param children - Dropdown Children. Should only be SelectDropdown.Item components.
 * @param defaultText - Text showing in select box when nothing was selected
 */
function SelectDropdown({ onClick, id, children, defaultText }) {
  const [activeItem, setActiveItem] = React.useState(null);
  const [showMenu, setShowMenu] = React.useState(false);

  const setItem = React.useCallback(element => setActiveItem(element), []);

  useEffectAfterMount(() => {
    onClick(activeItem);
  }, [activeItem]);

  const value = React.useMemo(() => ({ activeItem, setItem }), [
    activeItem,
    setItem
  ]);

  const closeMenu = () => {
    setShowMenu(false);
    document.removeEventListener("click", closeMenu);
  };

  const handleClick = event => {
    event.preventDefault();

    if (!showMenu) document.addEventListener("click", closeMenu);
    setShowMenu(true);
  };

  const dropdownMenuClass = classNames({
    "select-dropdown__menu": true,
    show: showMenu
  });

  return (
    <ClickContext.Provider value={value}>
      <div className="select-dropdown">
        <button
          aria-haspopup="true"
          aria-expanded={showMenu}
          id={id}
          className="select-dropdown__toggle"
          onClick={event => handleClick(event)}
        >
          {activeItem || defaultText}
        </button>
        <div aria-labelledby={id} className={dropdownMenuClass}>
          {children}
        </div>
      </div>
    </ClickContext.Provider>
  );
}

// Custom Hook for checking if SelectDropdown.Item is a child of SelectDropdown. Throws error if not.
function useClickContext() {
  const context = React.useContext(ClickContext);
  if (!context) {
    throw new Error(
      `SelectDropdown compound components cannot be rendered outside the SelectDropdown component`
    );
  }
  return context;
}

function Item({ children }) {
  const { setItem } = useClickContext();

  return <button onClick={() => setItem(children)}>{children}</button>;
}

/**
 * Static Member of SelectDropdown Component. Renders a button with a callback
 * @param children - Text Content of the button. Don't render anything else than a string in here.
 */
SelectDropdown.Item = Item;

SelectDropdown.propTypes = {
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default SelectDropdown;
