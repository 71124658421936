import React, { useEffect } from "react";
import Layout from "../../layouts/Layout";
import { graphql } from "gatsby";
import { Col, Container, Row } from "react-bootstrap";
import LinkFinder from "../../components/LinkFinder";
import Hint from "../../components/Hint";
import accordion from "../../helper/accordion";
import JoinTheProgram from "../../components/JoinTheProgram";
import SocialShare from "../../components/SocialShare";
import AmazonCategories from "../../components/AmazonCategories";

const Tips = ({ pageContext, data, location }) => {
  useEffect(() => {
    accordion.init();
  }, []);

  const page = data.page;
  const allChildPages = data.TipsChildPages.edges;
  const hint = data?.hint?.hint;

  const linkFinderCategories = data.LinkFinderCategories.edges;
  const linkFinderLinks = data.LinkFinderLinks.edges;

  const stringTranslations = pageContext.stringTranslations;

  return (
    <Layout
      context={pageContext}
      useContainer={false}
      className="tips-page"
      location={location}
      progressBarTarget="article"
    >
      <article>
        <header>
          <Container className="tips-child-page__header">
            <Row>
              <Col
                lg={{ span: 5, offset: 1 }}
                className="d-flex flex-column justify-content-center align-items-start mb-5"
              >
                <h1 dangerouslySetInnerHTML={{__html: page.post_title}} />
                {(page?.acf?.excerpt || page.post_excerpt) && (
                  <div
                    className="intro"
                    dangerouslySetInnerHTML={{
                      __html: page?.acf?.excerpt || page.post_excerpt
                    }}
                  />
                )}
              </Col>
              {page.image && (
                <Col lg={5}>
                  <img
                    srcSet={
                      page.image?.url?.localFile?.childImageSharp?.fluid?.srcSet
                    }
                    src={page.image?.url?.localFile?.childImageSharp?.fluid?.src}
                    alt={page.image.alt || page.post_title}
                  />
                </Col>
              )}
            </Row>
          </Container>
        </header>
        <Container>
          <Row>
            <Col
              lg={{ span: 6, offset: 1 }}
              className="content"
              dangerouslySetInnerHTML={{ __html: page.post_content }}
            />
            <Col lg={{ span: 3, offset: 1 }}>
              <LinkFinder
                categories={linkFinderCategories}
                links={linkFinderLinks}
                desc={stringTranslations["Get links to all important landing pages on Amazon.co.uk"]}
                title={stringTranslations["Link Finder"]}
                linkText={stringTranslations["Choose Link"]}
                categoryText={stringTranslations["Choose Category"]}
              />
              { hint && (
                <Hint title={stringTranslations["Hint"]}>{hint}</Hint>
              )}
            </Col>
          </Row>

          {pageContext?.signUpLink && (
            <Row>
              <Col lg={{ span: 6, offset: 1 }} style={{ zIndex: 0}}>
                <JoinTheProgram
                  link={pageContext.signUpLink}
                  variant="big"
                  title={stringTranslations["Join the associates programme"]}
                  benefit={stringTranslations["Earn up to 12% in commission income with a trusted e-commerce-leader"]}
                  btnText={stringTranslations["Sign up"]}
                />
              </Col>
            </Row>
          )}

          <Row>
            <Col lg={{ span: 6, offset: 1 }}>
              <SocialShare
                likeCount={2}
                postId={page.wordpress_id}
                path={page.path}
              />
            </Col>
          </Row>
        </Container>
      </article>

      <AmazonCategories
        title={stringTranslations["Amazon Categories"]}
        categories={allChildPages}
      />
    </Layout>
  );
};

export const query = graphql`
  query TipsQuery($id: Int!, $locale: String!) {
    page: wordpressPage(wordpress_id: { eq: $id }) {
      post_title
      post_excerpt
      wordpress_id
      image {
        alt
        url {
          localFile {
            childImageSharp {
              fluid(jpegQuality: 100, maxWidth: 980) {
                srcSet
                src
              }
            }
          }
        }
      }
      acf {
        excerpt
      }
      path
      post_content
    }
    TipsChildPages: allWordpressWpTips(
      filter: { language: { locale: { eq: $locale } } }
    ) {
      edges {
        node {
          path
          post_title
          acf {
            icon {
              url {
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
    }
    LinkFinderCategories: allWordpressWpLinkFinderCategories(
      filter: { language: { locale: { eq: $locale } } }
    ) {
      edges {
        node {
          title
          slug
        }
      }
    }
    LinkFinderLinks: allWordpressWpLinkFinderLinks(
      filter: { language: { locale: { eq: $locale } } }
    ) {
      edges {
        node {
          url
          title
          parent_slug
          parent_title
        }
      }
    }
    hint: wordpressWpTipsHint(language: {locale: {eq: $locale}}) {
      hint
    }
  }
`;

export default Tips;
