import React from "react";
import { Container, Card, Row, Col } from "react-bootstrap";
import { Link } from "gatsby";

const AmazonCategories = ({ title, categories }) => {
  if (!categories || categories.length === 0) return null;

  return (
    <Container fluid className="amazon-categories">
      <Row className="amazon-categories__title">
        <Col>{title}</Col>
      </Row>
      <Row className="amazon-categories__categories">
        {categories.map(category => {
          const icon = category.node?.acf?.icon?.url?.localFile?.publicURL;

          return (
            <Col md={6} lg={3} key={category.node.post_title}>
              <Link to={category.node.path}>
                <Card>
                  {icon && <Card.Img src={icon} />}
                  <Card.Body>{category.node.post_title}</Card.Body>
                </Card>
              </Link>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default AmazonCategories;
