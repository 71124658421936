import React from "react";
import PropTypes from "prop-types";

/**
 * Displays a hint section with icon and text. Mostly used in the sidebar.
 *
 * @param title - Title of the Hint section. Defaults to 'Hint'
 * @param icon - Which icon should be used. Uses icons.scss. icon- is automatically used e.g. 'bulb' converts to 'icon-bulb'
 * @param children - The content of the Hint section.
 */
const Hint = ({ title, icon, children }) => {
  return (
    <div className="hint">
      <div className="hint__header">
        <h5 className="hint__title">{title}</h5>
        <span className={`icon icon-${icon}`} />
      </div>
      <div className="hint__content" dangerouslySetInnerHTML={{ __html: children }} />
    </div>
  );
};

Hint.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string
};

Hint.defaultProps = {
  title: "Hint",
  icon: "bulb"
};

export default Hint;
